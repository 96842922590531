module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"rmetcalf-website","short_name":"rmetcalf","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFA5A5","display":"standalone","icon":"static/images/face-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e5801bbf16492641f958626917aa395"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
